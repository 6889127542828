export const state = () => ({
    webpSupported: null,
    isIos: null,
    isOsx: null,
    isTouch: null,
    isSafari: null,
    acceptFormats: '',
    yaMetricsObserverState: null,
});

export const getters = {
    getIsWebpSupported(state) {
        return state.webpSupported;
    },

    getIsIos(state) {
        return state.isIos;
    },

    getIsOsx(state) {
        return state.isOsx;
    },

    getIsSafari(state) {
        return state.isSafari;
    },

    getIsIpadPro(state) {
        return state.isTouch && state.isOsx && !state.isIos;
    },

    getYaMetricsObserverState(state) {
        return state.yaMetricsObserverState;
    },
};

export const actions = {
    async init({ dispatch, state }, headers) {
        const accept = headers['accept'];
        const userAgent = headers['user-agent'];

        if (accept) {
            dispatch('changeAcceptFormats', accept.split('/'));
            dispatch('changeIsWebpSupported', /\b(webp)\b/i.test(accept));
        }

        if (userAgent) {
            dispatch('changeIsIos', /(iPhone|iPad)/i.test(userAgent));
            dispatch('changeIsOsx', /(Mac)/i.test(userAgent));
            await dispatch('changeIsSafari', /^((?!chrome|android).)*safari/i.test(userAgent));

            if (state.isSafari) {
                dispatch('checkIsSafariWebpCompatible', userAgent);
            }
        }
    },

    /**
     * Проверка для версий safari - браузера, >= 16 работают с webp, но
     * но в accept headers webp не отдают
     * @param dispatch
     * @param payload {string} - Текущий userAgent реквеста
     */
    checkIsSafariWebpCompatible({ dispatch }, payload) {
        const matches = payload.match(/Version\/(\d+(?:\.\d+)?)/);

        if (matches?.length) {
            const versionNum = parseFloat(matches[1]);
            if (versionNum >= 16) {
                dispatch('changeIsWebpSupported', true);
            }
        }
    },

    setYaMetricsObserverState({ commit }, referer) {
        commit('SET_YA_STATE', referer === 'https://metrika.yandex.ru/' || referer === 'https://metrica.yandex.com/');
    },

    changeIsTouch({ commit }, payload) {
        commit('SET_IS_TOUCH', payload);
    },

    changeIsOsx({ commit }, payload) {
        commit('SET_IS_OSX', payload);
    },

    changeIsIos({ commit }, payload) {
        commit('SET_IS_IOS', payload);
    },

    changeIsSafari({ commit }, payload) {
        commit('SET_IS_SAFARI', payload);
    },

    changeAcceptFormats({ commit }, payload) {
        commit('SET_ACCEPT_FORMATS', payload);
    },

    changeIsWebpSupported({ commit }, payload) {
        commit('SET_WEBP_SUPPORTED', payload);
    },
};

export const mutations = {
    SET_YA_STATE(state, payload) {
        state.yaMetricsObserverState = payload;
    },

    SET_IS_TOUCH(state, payload) {
        state.isTouch = payload;
    },

    SET_IS_OSX(state, payload) {
        state.isOsx = payload;
    },

    SET_IS_IOS(state, payload) {
        state.isIos = payload;
    },

    SET_IS_SAFARI(state, payload) {
        state.isSafari = payload;
    },

    SET_ACCEPT_FORMATS(state, accept) {
        state.acceptFormats = accept;
    },

    SET_WEBP_SUPPORTED(state, support) {
        state.webpSupported = support;
    },
};
