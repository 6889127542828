// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Error_AfeS2{align-items:center;display:flex;flex-direction:column;height:100%;min-height:72rem;overflow:hidden;padding-left:3.6rem;padding-right:3.6rem;padding-top:6.4rem;position:relative;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"Error": "Error_AfeS2"
};
module.exports = ___CSS_LOADER_EXPORT___;
