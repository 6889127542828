export const scriptsCommonList = [
    /* Yandex Metrika.counter */
    {
        innerHTML: `
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(96624921, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });`,
        async: true,
    },

    /* Yandex.RTB */
    // Пока скрываем рекламу Яндекс РСЯ (tasks/132453)
    // { innerHTML: 'window.yaContextCb=window.yaContextCb||[]', type: 'text/javascript' },
    // {
    //     src: 'https://yandex.ru/ads/system/context.js',
    //     async: true,
    // },

    /* LiveInternet counter */
    {
        innerHTML: `
            (function(d,s){d.getElementById("licnt3C48").src=
            "https://counter.yadro.ru/hit?t40.1;r"+escape(d.referrer)+
            ((typeof(s)=="undefined")?"":";s"+s.width+"*"+s.height+"*"+
            (s.colorDepth?s.colorDepth:s.pixelDepth))+";u"+escape(d.URL)+
            ";h"+escape(d.title.substring(0,150))+";"+Math.random()})
            (document,screen)
        `,
        async: true,
    },

    /* Google Tag Manager */
    {
        innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-QHW7SJV7WT');
        `,
        async: true,
    },

    /* Top.Mail.Ru counter */
    {
        innerHTML: `
            var _tmr = window._tmr || (window._tmr = []);
            _tmr.push({id: "3545038", type: "pageView", start: (new Date()).getTime()});
            (function (d, w, id) {
              if (d.getElementById(id)) return;
              var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
              ts.src = "https://top-fwz1.mail.ru/js/code.js";
              var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
              if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
            })(document, window, "tmr-code");
        `,
        async: true,
    },

    /* a/b testing script */
    {
        innerHTML: `(function(e, x, pe, r, i, me, nt){
                        e[i]=e[i]||function(){(e[i].a=e[i].a||[]).push(arguments)},
                        me=x.createElement(pe),me.async=1,me.src=r,nt=x.getElementsByTagName(pe)[0],me.addEventListener('error',function(){
                        function cb(t){
                        t=t[t.length-1],'function'==typeof t&&t({flags:{}})
                        };
                        Array.isArray(e[i].a)&&e[i].a.forEach(cb);
                        e[i]=function() {
                        cb(arguments)}}),nt.parentNode.insertBefore(me,nt)
                        })
                        (window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');
                        ymab('metrika.96624921', 'init'/*, {clientFeatures}, {callback}*/);`,
        async: true,
    },

    /* Google Tag Manager */
    {
        src: 'https://www.googletagmanager.com/gtag/js?id=G-QHW7SJV7WT',
        async: true,
    },
];

export const noScriptsCommonList = [
    /* Yandex.Metrika counter */
    {
        innerHTML: '<div><img src="https://mc.yandex.ru/watch/96624921" style="position:absolute; left:-9999px;" alt="" /></div>',
        body: true,
    },

    /* Top.Mail.Ru counter */
    {
        innerHTML: '<div><img src="https://top-fwz1.mail.ru/counter?id=3545038;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div>',
        body: true,
    },
];
export const handleSetHeadSrc = scriptItem => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (scriptItem.async) {
        script.async = true;
    }

    script.src = scriptItem.src;
    document.head.appendChild(script);
};

export const handleSetHeadScript = (scriptItem, idx) => {
    const script = document.createElement('script');
    if (scriptItem.async) {
        script.async = true;
    }

    script.text = scriptItem.innerHTML;
    script.id = `script_${idx}`;
    document.head.appendChild(script);
    const found = document.querySelector(`script[id="script_${idx}"]`);

    if (found) {
        found.removeAttribute('id');
        found.parentNode.insertBefore(script, found);
    }
};

export const handleAddScripts = (array = scriptsCommonList) => {
    if (!array?.length) {
        return;
    }
    array.forEach((scriptItem, id) => {
        if (scriptItem.src) {
            handleSetHeadSrc(scriptItem);
        } else if (scriptItem.innerHTML) {
            handleSetHeadScript(scriptItem, id);
        }
    });
};

export default { scriptsCommonList, noScriptsCommonList };
