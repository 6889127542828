let resizeObserver = null;

export default {
    inserted(el, binding) {
        if (typeof binding.value === 'function') {
            resizeObserver = new ResizeObserver(binding.value);

            resizeObserver.observe(el);
        } else {
            console.error('resize directive: argument must be a function');
        }
    },

    unbind(el) {
        resizeObserver?.unobserve(el);
    },
};
