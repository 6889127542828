export const state = () => ({
    iframeElement: null,
});

export const actions = {
    setIframeElement({ commit }, val) {
        commit('SET_IFRAME_ELEMENT', val);
    },
};

export const mutations = {
    SET_IFRAME_ELEMENT(state, payload) {
        state.iframeElement = payload;
    },
};
