// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheCursor_ibxzK{align-items:center;background-color:#fff;border-radius:9999px;color:#09040d;display:flex;height:4.2rem;justify-content:center;left:-2.4rem;pointer-events:none;position:fixed;top:-2.4rem;width:4.2rem;will-change:transform;z-index:1000}@media(max-width:1279.98px){.TheCursor_ibxzK{display:none}}.TheCursor_ibxzK._video_IdC93{background-color:#0000;left:-4rem;top:-4rem}.TheCursor_ibxzK._video_IdC93,.TheCursor_ibxzK._video_IdC93 svg{height:8rem;width:8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"TheCursor": "TheCursor_ibxzK",
	"_video": "_video_IdC93"
};
module.exports = ___CSS_LOADER_EXPORT___;
