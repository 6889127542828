
import LogoIda from 'landing/components/common/LogoIda';

export default {
    name: 'TheFooter',

    components: { LogoIda },

    computed: {
        currentYear() {
            return new Date()?.getFullYear();
        },
    },
};
