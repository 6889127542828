export const state = () => ({
    customCursorState: '',
});

export const actions = {
    changeCursorState({ commit }, payload) {
        commit('SET_CURSOR', payload);
    },
};

export const mutations = {
    SET_CURSOR(state, payload) {
        state.customCursorState = payload;
    },
};
