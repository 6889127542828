export const state = () => ({});

export const getters = {};

export const actions = {
    async nuxtServerInit({ dispatch }, context) {
        try {
            await Promise.all([
                dispatch('domain/init', context.req.headers),
                dispatch('device/init', context.req.headers),
                dispatch('fetchLogo'),
                dispatch('device/setYaMetricsObserverState', context.req?.serverReferer),
            ]);
        } catch (e) {
            console.error('[nuxtServerInit]: ', e);
        }
    },

    async fetchLogo({ dispatch }) {
        try {
            const res = await this.$axios(this.$api.header.logo);

            dispatch('header/setLogoIcon', res.data.logo);
            dispatch('header/setPartnerLogoIcon', res.data.partner_log);
        } catch (e) {
            console.error('[nuxtServerInit]: ', e);
        }
    },
};

export const mutations = {};
