// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheFooter_p5-qM{display:flex;flex-direction:column;height:12.2rem;justify-content:space-between;padding:0 3.6rem 2.4rem;position:relative}@media(max-width:1279.98px){.TheFooter_p5-qM{height:auto;padding:0 1.6rem 3.2rem}}.top_DLQrE{border-bottom:1px solid #3a363d;padding-bottom:2.4rem}.bottom_qoRXS,.top_DLQrE{align-items:center;display:flex}.bottom_qoRXS{color:#fff;font-size:1.5rem;font-weight:400;line-height:2.1rem;margin-top:auto}@media(max-width:1279.98px){.bottom_qoRXS{align-items:flex-start;flex-direction:column;padding-top:2.4rem}}@media(max-width:767.98px){.bottom_qoRXS{font-size:1.4rem;line-height:135%}}.allRightsReserved_c7PWn{margin-right:auto}@media(max-width:1279.98px){.allRightsReserved_c7PWn{color:#fff;margin-bottom:1.6rem}}@media(max-width:767.98px){.allRightsReserved_c7PWn{margin-bottom:.8rem}}.link_pG7H7{border-bottom:1px solid #0000;color:#cecdcf;margin-left:1.2rem;position:relative;-webkit-text-decoration:none;text-decoration:none;transition:opacity .3s ease,border-bottom-color .3s ease}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.link_pG7H7:hover{border-bottom-color:#fff;color:#fff}}.link_pG7H7:active{opacity:.7}@media(max-width:1279.98px){.link_pG7H7{margin-left:0}.link_pG7H7:not(:last-of-type){margin-bottom:.8rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"TheFooter": "TheFooter_p5-qM",
	"top": "top_DLQrE",
	"bottom": "bottom_qoRXS",
	"allRightsReserved": "allRightsReserved_c7PWn",
	"link": "link_pG7H7"
};
module.exports = ___CSS_LOADER_EXPORT___;
