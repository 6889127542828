
export default {
    name: 'Error',

    layout: 'errorLayout',

    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
};
