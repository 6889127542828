// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "span.rub{font-family:PT-Rouble-Sans,sans-serif}.h0{font-size:23rem;letter-spacing:-.46rem}.h0,.h1{font-family:\"Bebas-Neue\",sans-serif;font-weight:700;line-height:1}.h1{font-size:9.4rem;letter-spacing:-.188rem}.h2{font-size:6.8rem;letter-spacing:-.136rem}.h2,.h3{font-family:\"Bebas-Neue\",sans-serif;font-weight:700;line-height:1}.h3{font-size:5.8rem;letter-spacing:-.116rem}.h4{font-size:4.2rem;letter-spacing:-.084rem}.h4,.h5{font-family:\"Bebas-Neue\",sans-serif;font-weight:700;line-height:1}.h5{font-size:3.6rem;letter-spacing:-.072rem}.h6{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1}.p2{font-size:1.35rem;line-height:1.4}.p2,.p3{font-weight:400}.p3{font-size:1.2rem;line-height:1.65rem}.c1{font-size:12px;font-weight:400;line-height:14px}.l2-m{font-size:.9rem;font-weight:500;line-height:1.05rem}.fade-enter-active,.fade-leave-active{transition:opacity 3s}.fade-enter,.fade-leave-active{opacity:0}.fade-fast-enter-active,.fade-fast-leave-active{transition:opacity .4s}.fade-fast-enter,.fade-fast-leave-active{opacity:0}.dropdown-enter,.dropdown-leave-to{opacity:0;transform:translate3d(0,16px,0)}.list-enter-active,.list-leave-active{transition:all .4s cubic-bezier(0,.555,.36,1.005)}.list-enter,.list-leave-to{opacity:0;transform:translateY(4rem)}.overlay-appear-enter-active{transition:all .4s}.overlay-appear-leave-active{transition:all .2s}.overlay-appear-enter,.overlay-appear-leave-active{opacity:0}.change-images-enter-active{transition:opacity .5s}.change-images-leave-active{transition:opacity .5s;transition-delay:.5s}.change-images-enter,.change-images-leave-active{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem"
};
module.exports = ___CSS_LOADER_EXPORT___;
