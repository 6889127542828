export function plural(num, postfixes) {
    if (!num && isNaN(num)) {
        return '';
    }

    const cases = [2, 0, 1, 1, 1, 2];
    return postfixes[num % 100 > 4 && num % 100 < 20 ? 2 : cases[Math.min(num % 10, 5)]];
}

export function copyToClipboard(text) {
    const input = document.createElement('input');

    document.body.appendChild(input);
    input.value = text;
    input.select();

    try {
        document.execCommand('copy');
        document.body.removeChild(input);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }
}

export function textFromArray(array) {
    if (Array.isArray(array) && array.length) {
        return array.reduce((acc, item, idx) => {
            if (idx === 0) {
                acc += item;
            } else {
                acc += `, ${item}`;
            }

            return acc;
        }, '');
    }
}

export function calculateTextWidth(text, element = null, font, letterSpacing = 'normal') {
    if (!text) {
        console.warn('[Utils/calculateTextWidth]: text is required arg', text);
        return;
    }

    if (!element && !font) {
        console.warn('[Utils/calculateTextWidth]: element or font should be determined', element, font);
        return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    let currentFont;
    let currentLetterSpacing;

    if (element && element instanceof HTMLElement && typeof window !== 'undefined') {
        const styles = getComputedStyle(element);
        currentFont = `${styles.fontWeight} ${styles.fontSize} ${styles.fontFamily}`;
        currentLetterSpacing = styles.letterSpacing;
    } else {
        currentFont = font;
        currentLetterSpacing = letterSpacing;
    }

    context.font = currentFont;
    context.letterSpacing = currentLetterSpacing;
    return context.measureText(text.toLowerCase()).width;
}

// Утилита, которая возвращает строку без HTML тегов
export function removeHTMLTags(text) {
    return text.replace(/<(.|\n)*?>/g, '');
}

// Утилита для декодирования HTML-сущностей
export function decodeEntities(encodedString) {
    const entitiesRegExp = /&(nbsp|amp|quot|lt|gt|laquo|raquo);/g;

    const dictionary = {
        nbsp: ' ',
        amp: '&',
        quot: '"',
        lt: '<',
        gt: '>',
        laquo: '«',
        raquo: '»',
    };

    return encodedString
        .replace(entitiesRegExp, (match, entity) => dictionary[entity])
        .replace(/&#(\d+);/gi, (match, numStr) => {
            const num = parseInt(numStr, 10);
            return String.fromCharCode(num);
        });
}
