
// Vuex
import { mapActions, mapGetters } from 'vuex';

// Components
import TheModal from '~/components/layout/TheModal';
import TheFooter from '../components/layout/TheFooter';
import TheHeader from '~/components/layout/TheHeader.vue';
import TheMenu from '~/components/layout/TheMenu';
import TheCursor from '~/components/layout/TheCursor.vue';

export default {
    name: 'DefaultLayout',
    components: {
        TheCursor,
        TheMenu,
        TheHeader,
        TheModal,
        TheFooter,
    },

    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: `${this.siteDomain}${this.$route.path}`,
                },
            ],

            meta: [
                {
                    hid: 'og:type',
                    name: 'og:type',
                    content: 'website',
                },
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `${this.siteDomain}${this.$route.path}`,
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            siteDomain: 'domain/getDomainAddress',
        }),

        showHeader() {
            return this.$route.path === '/' || this.$route.path === '/developers';
        },
    },

    mounted() {
        this.$nextTick(() => {
            const touchSupported = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0;

            this.setTouchSupport(touchSupported);
        });

        this.$sentry.captureException(new Error('test error in default layout landing on client'));
    },

    methods: {
        ...mapActions({
            setTouchSupport: 'device/changeIsTouch',
        }),
    },
};
