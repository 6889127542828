// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_uRW0x{min-height:100dvh;min-height:-webkit-fill-available;position:relative}.modal_NnOuM{bottom:0;left:0;position:fixed;right:0;top:0;z-index:1000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"main": "main_uRW0x",
	"modal": "modal_NnOuM"
};
module.exports = ___CSS_LOADER_EXPORT___;
