// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheModal_PC8\\+o.modal-enter-active:after,.TheModal_PC8\\+o.modal-leave-to:after{opacity:1;transition:opacity .3s ease}.TheModal_PC8\\+o.modal-enter-active .container_ztWGy,.TheModal_PC8\\+o.modal-leave-to .container_ztWGy{opacity:1;transform:translateZ(0);transition:opacity .2s ease .3s,transform .2s ease .3s}.TheModal_PC8\\+o.modal-enter:after,.TheModal_PC8\\+o.modal-leave-active:after{opacity:0;transition:opacity .3s ease .2s}.TheModal_PC8\\+o.modal-enter .container_ztWGy,.TheModal_PC8\\+o.modal-leave-active .container_ztWGy{opacity:0;transform:scale(.75);transition:opacity .2s ease,transform .2s ease}@media(max-width:1279.98px){.TheModal_PC8\\+o.modal-enter .container_ztWGy,.TheModal_PC8\\+o.modal-leave-active .container_ztWGy{transform:scale(.75)}}.TheModal_PC8\\+o:after{background-color:#09040d66;bottom:0;content:\"\";left:0;position:absolute;right:0;top:0;z-index:1}@media(max-width:1279.98px){.TheModal_PC8\\+o._fullscreen_RHxUJ .container_ztWGy{width:100%}}.TheModal_PC8\\+o .wrapper_Q2X\\+t{align-items:flex-start;display:flex;height:100%;justify-content:flex-end;position:relative;width:100%;z-index:2}.TheModal_PC8\\+o .container_ztWGy{background-color:#09040d;display:block;height:100%;overflow-y:auto;width:100%}@media(max-width:1279.98px){.TheModal_PC8\\+o .container_ztWGy{width:44rem}}@media(max-width:767.98px){.TheModal_PC8\\+o .container_ztWGy{bottom:0;position:absolute;width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"TheModal": "TheModal_PC8+o",
	"container": "container_ztWGy",
	"_fullscreen": "_fullscreen_RHxUJ",
	"wrapper": "wrapper_Q2X+t"
};
module.exports = ___CSS_LOADER_EXPORT___;
