import mainPage from '~/config/api/mainPage';
import documents from '~/config/api/documents';
import companies from '~/config/api/companies';
import events from '~/config/api/events';
import feedback from '~/config/api/feedback';
import offers from '~/config/api/offers';
import socials from '~/config/api/socials';
import formRequests from '~/config/api/formRequests';
import advertiser from '~/config/api/advertiser';
import club from '~/config/api/club';
import developers from '~/config/api/developers';
import header from '~/config/api/header';

const apiConfig = {
    ...mainPage,
    ...documents,
    ...companies,
    ...events,
    ...feedback,
    ...offers,
    ...socials,
    ...formRequests,
    ...advertiser,
    ...club,
    ...developers,
    ...header,
};

export default apiConfig;
