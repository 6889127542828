// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogoIda_G7rjY{cursor:pointer}.LogoIda_G7rjY._black_BnAUF{--color:#848286}.LogoIda_G7rjY._white_pUEU8{--color:#fff}.wrapper_g5PP4{align-items:center;display:flex;justify-content:space-between}@media(max-width:767.98px){.wrapper_g5PP4{align-items:flex-start}}.dvizhLogo_aT34N{height:2.8rem;margin-right:.8rem;width:2.8rem}.inner_UXItX{height:2.8rem;padding:1px;position:relative;width:9.2rem}.rectangleBottom_ngVvT,.rectangleTop_lu6T-{left:-1px;overflow:hidden;position:absolute}.rectangleBottom_ngVvT span,.rectangleTop_lu6T- span{background:linear-gradient(to right,var(--color) 50%,#0000 50%) 100%;background-size:200% 100%;height:100%;left:-2px;position:absolute;right:-2px}.rectangleTop_lu6T-{height:50%;top:0;width:39%}.rectangleBottom_ngVvT{bottom:0;height:calc(50% + 1px);width:100%}.logoIcon_See6v{color:var(--color);height:100%;transform:translateZ(0);width:100%}.text_SHfNM{color:#848286;font-size:12px;font-weight:400;line-height:14px;margin-left:4rem;position:relative;white-space:nowrap}.text_SHfNM .mobile{display:none}.text_SHfNM:before{background-color:#3a363d;content:\"\";height:100%;left:-2rem;position:absolute;top:0;width:1px}@media(max-width:767.98px){.text_SHfNM .desktop{display:none}.text_SHfNM .mobile{display:block}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"LogoIda": "LogoIda_G7rjY",
	"_black": "_black_BnAUF",
	"_white": "_white_pUEU8",
	"wrapper": "wrapper_g5PP4",
	"dvizhLogo": "dvizhLogo_aT34N",
	"inner": "inner_UXItX",
	"rectangleBottom": "rectangleBottom_ngVvT",
	"rectangleTop": "rectangleTop_lu6T-",
	"logoIcon": "logoIcon_See6v",
	"text": "text_SHfNM"
};
module.exports = ___CSS_LOADER_EXPORT___;
