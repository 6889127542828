// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VIcon_bEU8j._xs_c1Q3O{height:1rem;width:1rem}.VIcon_bEU8j._small_gklBj{height:1.2rem;width:1.2rem}.VIcon_bEU8j._medium_xB-FJ{height:1.6rem;width:1.6rem}.VIcon_bEU8j._semi-large_CSG\\+4{height:2rem;width:2rem}.VIcon_bEU8j._large_fHbfl{height:2.4rem;width:2.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VIcon": "VIcon_bEU8j",
	"_xs": "_xs_c1Q3O",
	"_small": "_small_gklBj",
	"_medium": "_medium_xB-FJ",
	"_semi-large": "_semi-large_CSG+4",
	"_large": "_large_fHbfl"
};
module.exports = ___CSS_LOADER_EXPORT___;
