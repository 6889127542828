
// vuex
import { mapActions, mapGetters } from 'vuex';
// Components
import TheModal from '~/components/layout/TheModal';
import { handleAddScripts, scriptsCommonList } from '../../common/assets/js/utils/scripts';

export default {
    name: 'EmptyLayout',

    components: {
        TheModal,
    },

    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: `${this.siteDomain}${this.$route.path}`,
                },
            ],

            meta: [
                {
                    hid: 'og:type',
                    name: 'og:type',
                    content: 'website',
                },
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `${this.siteDomain}${this.$route.path}`,
                },
            ],

            script: [
                // Metrics
                this.getYaMetricsObserverState && scriptsCommonList[0],
            ],
        };
    },

    computed: {
        ...mapGetters({
            getYaMetricsObserverState: 'device/getYaMetricsObserverState',
        }),
    },

    mounted() {
        if (this.getYaMetricsObserverState) {
            const filteredScripts = [...scriptsCommonList];
            filteredScripts.shift();
            handleAddScripts(filteredScripts);
        } else {
            handleAddScripts();
        }

        this.$nextTick(() => {
            const touchSupported = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0;

            this.setTouchSupport(touchSupported);
        });
    },

    methods: {
        ...mapActions({
            setTouchSupport: 'device/changeIsTouch',
        }),
    },
};
