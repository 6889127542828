
// vuex
import { mapState } from 'vuex';
// components
import VIcon from '~/components/ui/icon/VIcon.vue';

export default {
    name: 'TheCursor',

    components: {
        VIcon,
    },

    data() {
        return {
            coords: {
                x: 0,
                y: 0,
            },
        };
    },

    computed: {
        ...mapState({
            customCursorState: state => state.cursor.customCursorState,
        }),

        classes() {
            return [
                this.$style[`_${this.customCursorState}`],
            ];
        },
    },

    watch: {
        customCursorState(val) {
            if (!this.$deviceIs.pc) {
                return;
            }

            if (val) {
                document.addEventListener('mousemove', this.calcCursorPosition);

                return;
            }

            document.removeEventListener('mousemove', this.calcCursorPosition);
        },
    },

    methods: {
        calcCursorPosition(e) {
            requestAnimationFrame(() => {
                this.coords.x = e.clientX + 'px';
                this.coords.y = e.clientY + 'px';
            });
        },
    },
};
