import { gsap } from 'gsap/dist/gsap.js';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.js';
import { plural } from '@@/common/assets/js/utils/text-utils';
import { splitThousands } from '@@/common/assets/js/utils/numbers-utils';

gsap.registerPlugin(ScrollToPlugin);

export function lockBody() {
    console.warn('lockBody устарел, используйте disablePageScroll. Документация: https://github.com/FL3NKEY/scroll-lock/blob/master/README.RU.md');
}

export function unlockBody() {
    console.warn('unlockBody устарел, используйте enablePageScroll. Документация: https://github.com/FL3NKEY/scroll-lock/blob/master/README.RU.md');
}

export function scrollTo(id = false, offset = 0, force = false) {
    const target = document.getElementById(id || '__nuxt');

    if (target) {
        const position = target.getBoundingClientRect().top + window.pageYOffset;

        if (force) {
            window.scroll({
                top: position - offset,
                left: 0,
                behavior: 'instant',
            });
        } else {
            gsap.to(window, {
                duration: .5,
                scrollTo:
                    { y: position, offsetY: offset },
            });
        }
    }
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function executedFunction() {
        // eslint-disable-next-line
        const context = this;
        const args = arguments;

        function later() {
            timeout = null;

            if (!immediate) {
                func.apply(context, args);
            }
        }

        const callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
}

export function scrollToForm() {
    scrollToWithBlackout('home-form');
}

export function scrollToWithBlackout(id = false, offset = 0) {
    document.body.style.opacity = '0';

    setTimeout(() => {
        const target = document.getElementById(id || '__nuxt');

        gsap.to(window, {
            duration: .5,
            scrollTo:
                {
                    y: target,
                    offsetY: offset,
                },
        });
    }, 300);

    setTimeout(() => {
        document.body.style.opacity = '1';
    }, 600);
}

export function freeze(time) {
    return new Promise((res, rej) => {
        setTimeout(() => {
            res();
        }, time);
    });
}

/* eslint-disable */
export function throttle(func, ms) {
    let isThrottled = false;
    let savedArgs;
    let savedThis;

    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(function() {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

export function remToPx(rem) {
    return parseFloat(rem) * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function formattedExperience(experience) {

    if (experience && typeof experience === 'string') {
        const regexp = /\d/g;
        const result = experience.match(regexp);

        if (result) {
            if (result.length === 2) {
                return `${result[0]}-${result[1]} ${plural(result[1], ['год', 'года', 'лет'])}`
            } else if (result.length === 1) {
                return `Более ${result[0]} ${plural(result[0], ['год', 'года', 'лет'])}`
            } else {
                return experience;
            }
        } else {
            return experience;
        }
    }

    return '';
}

export function formattedSalary(item) {
    const { income_to, income_from, currency } = item;

    if (!income_from && !income_to) {
        return 'з/п не указана';
    }
    return `${income_from ?
        'от ' + splitThousands(income_from)
        : ''}${income_to
        ? ' до ' + splitThousands(income_to)
        : ''} ${currency || '₽'}`;
}

export function scrollToHorizontalCenter(parentBlock, childBlock) {
    const width = childBlock?.getBoundingClientRect()?.width;

    if (parentBlock && width) {
        parentBlock.scrollLeft = width / 2 - window.innerWidth / 2;
    }
}

export function filteringFalsyValues(obj) {
    if (!Object.keys(obj).length) {
        return {};
    }

    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];

        if (Array.isArray(value) && value.length) {
            acc[key] = value;
        } else if (typeof value === 'boolean' && value === true) {
            acc[key] = value;
        } else if (typeof value === 'string' && value) {
            acc[key] = value;
        } else if (typeof value === 'number') {
            acc[key] = value;
        }

        return acc;
    }, {});
}

// Функция для получения youtube id из ссылки youtube
export function getYoutubeId(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length ===11 ? match[7] : false;
};

// Функция принимает обьект и массив его ключей, значения которых являются массивом и приводит элементы этих массивов к числу

export function convertStringToNumber(obj, keys) {
    keys.forEach(key => {
        if (obj[key] && Array.isArray(obj[key])) {
            obj[key] = obj[key].map(el => Number(el))
        }
    });
}


export function formatList(
    list, // Массив данных
    specs, // Спеки
    keys // Ключи по которым нужно заменить id на спеки
) {
    if (specs && list && keys) {
        const clonedList = JSON.parse(JSON.stringify(list));

        return clonedList.map(item => {
            keys.forEach(key => {
                let field = item[key];

                if (field && Array.isArray(field)) {
                    field = field.map(fieldItem => {
                        const spec = specs?.[key]?.find(s => s.value === fieldItem);

                        return spec;
                    });
                } else if (field !== null && field !== undefined) {
                    const spec = specs?.[key]?.find(s => s.value === field);

                    field = spec;
                }

                item[key] = field;
            });

            return item;
        });
    }

    return null;
}

export function generateUniqueId() {
    // Генерация случайного числа, конвертация в 36-ричную систему и обрезка строки для получения уникального id.
    return Math.random().toString(36).substr(2, 16);
}

// Проверка объекта на наличие хотябы одного поля с каким-либо значением, игнорируя поля в массиве ignoredFields
export function checkObjectForValues(obj, ignoredFields = []) {
    return Object.entries(obj).some(([key, value]) => !ignoredFields.includes(key) && value);
}
/* eslint-enable */
