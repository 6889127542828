import Vue from 'vue';

// Если уже добавлен плагин в /config/plugins, сюда дублировать не нужно.
import ClickOutside from '~/directives/clickoutside';
import Resize from '~/directives/resize';
import LazyLoad from '~/directives/vanilla-lazyload';
import Intersect from '~/directives/intersect';

Vue.directive('clickoutside', ClickOutside);
Vue.directive('resize', Resize);
Vue.directive('lazy', LazyLoad);
Vue.directive('intersect', Intersect);
