// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{background-color:#09040d;font-size:13.3333px;height:100%;min-height:1vw}@media(max-width:1919.98px){html{font-size:.6944444444vw}}@media(max-width:1279.98px){html{font-size:.9844444444vw}}@media(max-width:767.98px){html{font-size:10px}}body{color:#fff;font-family:\"Formular\",sans-serif;font-size:1.6rem;overflow-y:scroll;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;-webkit-tap-highlight-color:rgba(0,0,0,0);transition:opacity .3s ease}.container{margin:0 auto;padding:0 3.6rem}@media(max-width:1279.98px){.container{padding:0 2.4rem}}@media(max-width:767.98px){.container{padding:0 1.6rem}}.visually-hidden:not(:focus):not(:active){height:1px;overflow:hidden;position:absolute;width:1px;clip:rect(1px 1px 1px 1px);clip:rect(1px,1px,1px,1px);-webkit-clip-path:inset(50%);clip-path:inset(50%);white-space:nowrap}.styled-content h1,.styled-content h2,.styled-content h3{font-weight:600}.styled-content h1{font-size:2rem;margin:2rem 0}@media(max-width:767.98px){.styled-content h1{font-size:24px;margin-bottom:12px}}.styled-content h2{font-size:1.6rem;margin-bottom:1.4rem}@media(max-width:767.98px){.styled-content h2{font-size:20px;margin-bottom:14px}}.styled-content h3{font-size:1.4rem;margin-bottom:1rem}@media(max-width:767.98px){.styled-content h3{font-size:18px;margin-bottom:12px}}.styled-content p{margin:1rem 0}@media(max-width:767.98px){.styled-content p{font-size:16px;margin:10px 0}}.styled-content p:first-child{margin-top:0}.styled-content b,.styled-content strong{font-weight:bolder}.styled-content ol,.styled-content ul{list-style:none;margin:1rem 0;padding:0 0 0 3rem}.styled-content li{list-style:inherit;margin:.5rem 0}.styled-content ul{list-style-type:disc}.styled-content ol{list-style-type:decimal}.styled-content hr{margin:1rem 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem"
};
module.exports = ___CSS_LOADER_EXPORT___;
