export const state = () => ({
    isScrolled: false, // Производится ли скролл вообще
    isVisible: true, // Отображение и скрытие меню
    isTransparent: false, // Включение прозрачности меню
    isMenuWithHero: false, // Меняем прозрачность, если у страницы есть в начале компонент heroBanner
    isBurgerMenuVisible: false, // Отслеживание состояние бургер меню, чтобы отключать изменение состояний
    activeMenuItemId: '',
    logoIcon: '',
    partnerLogoIcon: '',
});

export const actions = {
    setScrolled({ commit }, val) {
        commit('SET_SCROLLED', val);
    },

    changeVisibility({ commit }, val) {
        commit('CHANGE_VISIBILITY', val);
    },

    setTransparent({ commit }, val) {
        commit('SET_TRANSPARENT', val);
    },

    setMenuWithHero({ commit, dispatch }, val) {
        if (val === true) {
            commit('SET_MENU_WITH_HERO', true);
        } else if (val === false) {
            dispatch('setTransparent', false);
            commit('SET_MENU_WITH_HERO', false);
        }
    },

    changeBurgerMenuState({ commit }, val) {
        commit('SET_BURGER_MENU_STATE', val);
    },

    changeActiveMenuItemId({ commit }, val) {
        commit('SET_ACTIVE_MENU_ITEM_ID', val);
    },

    setLogoIcon({ commit }, val) {
        commit('SET_LOGO_ICON', val);
    },

    setPartnerLogoIcon({ commit }, val) {
        commit('SET_PARTNER_LOGO_ICON', val);
    },
};

export const mutations = {
    SET_SCROLLED(state, payload) {
        state.isScrolled = payload;
    },

    CHANGE_VISIBILITY(state, payload) {
        state.isVisible = payload;
    },

    SET_TRANSPARENT(state, payload) {
        state.isTransparent = payload;
    },

    SET_MENU_WITH_HERO(state, payload) {
        state.isMenuWithHero = payload;
    },

    SET_BURGER_MENU_STATE(state, payload) {
        state.isBurgerMenuVisible = payload;
    },

    SET_ACTIVE_MENU_ITEM_ID(state, payload) {
        state.activeMenuItemId = payload;
    },

    SET_LOGO_ICON(state, payload) {
        state.logoIcon = payload;
    },

    SET_PARTNER_LOGO_ICON(state, payload) {
        state.partnerLogoIcon = payload;
    },
};
