// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultLayout_15f8X{display:flex;flex-direction:column;min-height:100vh;min-height:100dvh;width:100%}.main_yr\\+78{flex-grow:1;min-height:calc(100vh - 12.2rem);min-height:100dvh;min-height:-webkit-fill-available;position:relative}.footer_5\\+Lsh,.main_yr\\+78{width:100%}.modal_enUcf{bottom:0;left:0;position:fixed;right:0;top:0;z-index:1000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"DefaultLayout": "DefaultLayout_15f8X",
	"main": "main_yr+78",
	"footer": "footer_5+Lsh",
	"modal": "modal_enUcf"
};
module.exports = ___CSS_LOADER_EXPORT___;
