// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheMenu_LpKBa{bottom:0;left:0;pointer-events:none;position:fixed;right:0;top:0;visibility:hidden;z-index:12}.TheMenu_LpKBa._open_JdvHd{pointer-events:auto;visibility:visible}.TheMenu_LpKBa._open_JdvHd .bg_\\+a530{opacity:1}.TheMenu_LpKBa._open_JdvHd .wrapper_DA7Ip{transform:translateX(0)}@media(max-width:1279.98px){.TheMenu_LpKBa._open_JdvHd .wrapper_DA7Ip{opacity:1;transform:scale(1)}}.bg_\\+a530{background-color:#000c;bottom:0;left:0;opacity:0;position:absolute;right:0;top:0;transition:all .3s ease;z-index:1}.wrapper_DA7Ip{height:100%;padding-top:6.4rem;pointer-events:none;position:relative;transform:translateX(-110%);transition:all .3s,transform .3s,opacity .3s;z-index:1}@media(max-width:1279.98px){.wrapper_DA7Ip{opacity:0;padding-top:5.2rem;transform:scale(.8)}}.menu_u9Ai8{pointer-events:auto;position:relative;z-index:9}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"TheMenu": "TheMenu_LpKBa",
	"_open": "_open_JdvHd",
	"bg": "bg_+a530",
	"wrapper": "wrapper_DA7Ip",
	"menu": "menu_u9Ai8"
};
module.exports = ___CSS_LOADER_EXPORT___;
