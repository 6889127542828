// @todo добавить настоящие id для секций
export const menuItems = [
    // {
    //     id: 'home-about-anchor',
    //     label: 'О клубе',
    // },
    // {
    //     label: 'Для кого',
    //     id: 'home-purpose-anchor',
    // },
    // {
    //     label: 'Резидентство в клубе',
    //     id: 'home-residence',
    // },
    // {
    //     label: 'Об организаторе',
    //     id: 'home-organizer',
    // },
    {
        label: 'Резиденство в клубе',
        id: 'home-residence',
    },
    // {
    //     label: 'Об организаторе',
    //     id: 'home-events-anchor',
    // },
    {
        label: 'События',
        id: 'home-events',
    },
    {
        label: 'Девелоперам',
        id: 'developers',
        link: '/developers',
    },

];
