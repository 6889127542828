
// Vuex
import { mapActions } from 'vuex';

// Utils
import { disablePageScroll, enablePageScroll } from 'scroll-lock/dist/scroll-lock';

export default {
    name: 'TheModal',

    data() {
        return {
            component: null,
            attrs: null,
            onClose: null,
            key: null,
        };
    },

    computed: {
        classList() {
            return [
                { [this.$style._fullscreen]: this.attrs.fullscreen },
            ];
        },
    },

    beforeMount() {
        this.$modal.event.$on('open', this.open);
        this.$modal.event.$on('update', this.update);
        this.$modal.event.$on('close', this.close);
    },

    beforeDestroy() {
        this.$modal.event.$off('open', this.open);
        this.$modal.event.$off('update', this.update);
        this.$modal.event.$off('close', this.close);
        enablePageScroll();
    },

    methods: {
        ...mapActions('header', [
            'setTransparent',
            'setMenuWithHero',
        ]),

        onChange(val) {
            this.$modal.event.$emit('change', val);
            if (val === 'reset') {
                this.close();
            }
        },

        open(component, attrs, onClose) {
            this.component = component;
            this.attrs = attrs;
            this.onClose = onClose;
            this.key = Math.random();
            this.setTransparent(false);
        },

        update(attrs) {
            this.attrs = attrs;
        },

        close() {
            if (this.onClose) {
                this.onClose();
            }

            this.$modal.event.$off('change');
            this.component = this.attrs = this.onClose = this.key = null;
        },

        beforeEnter() {
            disablePageScroll();
        },

        afterLeave() {
            enablePageScroll();
        },
    },
};
